import React from "react";
import { Helmet } from "react-helmet";
import {useStaticQuery , graphql } from "gatsby"


const SEO = ({ title , description}) => {
    const { site }  = useStaticQuery(getData);
    const { siteTitle, siteDesc ,siteUrl ,image} = site.siteMetadata
    return(
        <Helmet htmlAttributes={{ lang: "en" }} title={`${title} | ${siteTitle}`} >
            < meta name="description" content={description || siteDesc} />
            < meta name="image" content={image} />
        </Helmet>
    )
}
// so i just have to name the query getData , 

export const getData = graphql`
  {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        author
        siteUrl
        image
      }
    }
  }
`

export default SEO
